<!-- 信息验证 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <div class="header-content">智能营养筛查评估系统</div>
    </header>
    <section class="page-info">
      <div class="logo-module">
        <img src="@/assets/logo.png" class="logo-img">
        <div class="logo-content">
          演示医院
        </div>
      </div>
      <div class="info-form">
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field
              v-model="formData.name"
              name="name"
              label="姓名"
              readonly
              placeholder="姓名"
            />
            <van-field
              v-model="formData.cure_sn"
              name="cure_sn"
              label="住院号"
              required
              placeholder="住院号"
              :rules="[{ required: true }]"
            />
            <van-field
              v-model="formData.id_card"
              name="id_card"
              maxlength="6"
              label="身份证号"
              required
              placeholder="身份证后6位"
              :rules="[{ required: true }]"
            />
          </van-cell-group>
          <div class="id-card-attention">
            为了患者的数据安全与隐私，请输入<span style="color:#FF3300">身份证后6位</span>进行验证。
          </div>
          <div class="bind-commit">
            <div class="agreements">
              <van-checkbox v-model="checked" shape="round" checked-color="#00FFCC" icon-size="14px" />
              <span style="margin-left:5px;">
                同意
              </span>
              <span class="agreement-item" @click="goUserServe">
                《用户服务协议》
              </span>
              <span>
                与
              </span>
              <span class="agreement-item" @click="goPrivacyPolicy">
                《隐私政策》
              </span>
            </div>
            <van-button :disabled="!checked" class="bind-btn" native-type="submit" round block>绑定</van-button>
          </div>
        </van-form>
      </div>
      
    </section>

  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Toast } from 'vant'
import bind from "@/api/user/bind"

export default {
  name: "bind",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
    });

    const onSubmit = (values) => {
      if (!data.checked) {
        Toast('请先同意用户服务协议及隐私政策！');
        return
      }
      console.log('submit', values);
      console.log(data.formData)
      if (data.formData.id_card.length != 6) {
        Toast('身份证信息异常，请检查再提交！');
        return
      }
      const post_data = {
        cure_sn: data.formData.cure_sn,
        id_card: data.formData.id_card,
      }
      bind.bindInfo(post_data).then(res => {
        $router.push({
          name: 'home',
        })
      })
    };

    const data = reactive({
      formData: {
        name: '张三',
        id_card: '',
        cure_sn: '20040511213642002'
      },
      checked: true
    });
    const goUserServe = () => {
      $router.push({
        name: 'user-service'
      })
    }
    const goPrivacyPolicy = () => {
      $router.push({
        name: 'privacy-policy'
      })
    }

    return {
      ...toRefs(data),
      onSubmit,
      goUserServe,
      goPrivacyPolicy
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  background: #fff;
  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;
    background: #fff;
    .btn-left {
      position: fixed;
      left: 16px;
      background-color: #efeff4;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }
    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }
  .page-info {
    background-color: #fff;
    min-height: 200px;
    margin: 20px 20px;
    .logo-module {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      .logo-content {
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
      }
      .logo-img {
        width: 60px;
        height: 60px;
      }
    }
    .id-card-attention {
      padding: 10px 0 10px 100px;
      font-size: 12px;
      line-height: 16px;
      color: #333;
    }
    .bind-commit {
      width: 100%;
      margin-top: 20px;
      .agreements {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 20px 0;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
        .agreement-item {
          color: #FF3333;
        }
      }
      .bind-btn {
        background: #00FFCC;
        color: #fff;
        font-size: 20px;
        height: 40px;
        font-weight: bold;
      }
    }
  }
}
</style>
